import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/website/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "setup-your-aws-account"
    }}>{`Setup your AWS Account`}</h1>
    <ol>
      <li parentName="ol">{`Create your account on the `}<a parentName="li" {...{
          "href": "https://aws.amazon.com/getting-started/"
        }}>{`AWS Getting Started Page`}</a>{`.`}</li>
      <li parentName="ol">{`From `}<a parentName="li" {...{
          "href": "https://docs.aws.amazon.com/IAM/latest/UserGuide/getting-started.html"
        }}>{`Getting Started`}</a>{`, `}<a parentName="li" {...{
          "href": "https://docs.aws.amazon.com/IAM/latest/UserGuide/getting-started_create-admin-group.html#getting-started_create-admin-group-console"
        }}>{`Create Your First IAM Admin User`}</a>{`.`}</li>
      <li parentName="ol">{`From `}<a parentName="li" {...{
          "href": "https://docs.aws.amazon.com/IAM/latest/UserGuide/id_credentials_mfa_enable_virtual.html"
        }}>{`Setup MFA`}</a>{`, setup IAM for both your root account and new user using the Google Authenticator app.`}</li>
      <li parentName="ol">{`On the `}<a parentName="li" {...{
          "href": "https://console.aws.amazon.com/iam/home#/home"
        }}>{`IAM Dashboard`}</a>{`, set an alias for your account so you don't need the special url to login.`}</li>
      <li parentName="ol">{`Enable access to the Billing Console for IAM users by following `}<a parentName="li" {...{
          "href": "https://docs.aws.amazon.com/IAM/latest/UserGuide/tutorial_billing.html?icmpid=docs_iam_console"
        }}>{`Delegate Access to the Billing Console`}</a>{`. Attach the Billing policy to the Administrator group.`}</li>
      <li parentName="ol">{`Log out of your root account and login with your user account.`}</li>
      <li parentName="ol">{`Change your currency preference by going to `}<a parentName="li" {...{
          "href": "https://console.aws.amazon.com/billing/home"
        }}>{`My Account`}</a>{`, Payment Currency Preference.`}</li>
      <li parentName="ol">{`Create an `}<a parentName="li" {...{
          "href": "https://aws.amazon.com/aws-cost-management/aws-budgets/"
        }}>{`AWS Budget`}</a>{` so you don't get charged too much.`}</li>
    </ol>
    <p>{`Now you can follow `}<a parentName="p" {...{
        "href": "./SetupAWSCloudInfrastructure.md"
      }}>{`Setup AWS Cloud Infrastructure`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      